import React from 'react';
import ReactDOM from 'react-dom';
import ToggleLangaugeReact from './component/core__toggleLanguageReact';

class ToggleLanguageApp {
  constructor(properties) {
    Object.assign(this, properties);

    if (this.nodeSSR) return;

    this.start();
  }

  start() {
    const ReactInstance = this.getReactApp();
    const jsx = <ReactInstance appConfig={this.appConfig} />;

    ReactDOM['render'](jsx, this.appSection);
  }

  getReactApp() {
    return ToggleLangaugeReact;
  }
}
export default ToggleLanguageApp;
