import styled from 'styled-components';
import { BRAND } from 'UI/globals/colours';

export const DD = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 3px;
  width: 100px;
  border-radius: 24px;
  margin: 0 0 0 10px;
  text-transform: capitalize;
  background: ${BRAND.secondaryD} div {
    padding: 0px;
    background-color: ${BRAND.secondaryD};
    padding: 3px;
  }
`;

export const DL = styled.img`
  height: 12px;
  width: 17px;
  position: absolute;
  right: 2px;
  margin-right: 8px;
`;

export const DB = styled.img`
  height: 12px;
  width: 17px;
  position: absolute;
  right: 46px;
  margin-top: 2px;
`;
