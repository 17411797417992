import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';
// import { Select } from '../../globals/Select/core__select';
import theme from 'UI/globals/theme';
import { GREYS, BRAND, FUNCTIONAL } from 'UI/globals/colours';
import Select from 'react-select';

const StyledSelect = styled(Select)`
  font-family: ${theme.fontFamily};
  font-size: 12px;
  margin: 0;
  div[class*='control'] {
    margin-top: -5px;
    width: 111px;
  }
  div[class*='ValueContainer'] {
    flex: none;
    padding: 2px;
    margin-left: 16px;
  }
  div[class*='menu'] {
    position: absolute;
    top: 20px;
    right: 40px;
    width: 80px;
  }
  div[class*='singleValue'] {
    color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.white)};
    width: 100%;
    max-width: initial;
    overflow: hidden;
    text-overflow: initial;
    white-space: initial;
    top: auto;
    transform: none;
    box-sizing: border-box;
    position: static;
    padding-right: 4px;
    margin: 0;
    width: 40px;
    text-indent: -9999px;
    img {
      display: none;
    }
    &:after {
      margin-left: 8px;
      content: '';
      border: ${({ theme }) =>
        theme.dark ? `solid  ${GREYS.black}` : `solid ${GREYS.white}`};
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 4px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      position: relative;
      top: -18px;
      float: right;
    }
  }
`;

const styles = {
  control: provided => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    ':hover': {
      border: 'none',
      boxShadow: 'none',
      backgroundColor: 'none',
    },
    justifyContent: 'center',
    minHeight: 'auto',
    backgroundColor: 'none',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: `${GREYS.black}`,
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
  valueContainer: provided => ({
    ...provided,
    justifyContent: 'center',
    alignItems: 'center',
    border: ``,
    ':hover': {
      border: ``,
    },
    ':focus': {
      border: `1px solid ${BRAND.primary}`,
    },
    flexWrap: 'initial',
    padding: '8px',
  }),
  option: provided => ({
    ...provided,
    flex: 1,
    justifyContent: 'space-between',
    color: GREYS.white,
    borderRadius: '16px',
    backgroundColor: '#0000',
    ':hover': {
      border: 'none',
      boxShadow: 'none',
      backgroundColor: '#0000',
    },
  }),
  menu: provided => ({
    ...provided,
    minWidth: '100px',
    color: GREYS.white,
    backgroundColor: FUNCTIONAL.documentU,
    borderRadius: '16px',
    ':hover': {
      border: 'none',
      boxShadow: 'none',
      backgroundColor: 'none',
    },
  }),
};

export const ContactDropdown = ({ ...props }) => {
  const {
    options,
    defaultValue,
    defaultLabel,
    onChange,
    menuIsOpen,
    instanceId,
  } = props;
  return (
    <ThemeProvider theme={theme}>
      <StyledSelect
        isSearchable={false}
        styles={styles}
        options={options}
        defaultValue={
          defaultValue && defaultLabel
            ? { value: defaultValue, label: defaultLabel }
            : options[0]
        }
        instanceId={instanceId}
        onChange={onChange && onChange}
        menuIsOpen={menuIsOpen}
      ></StyledSelect>
    </ThemeProvider>
  );
};

ContactDropdown.propTypes = {
  options: PropTypes.array,
  defaultValue: PropTypes.string,
  defaultLabel: PropTypes.string,
  onChange: PropTypes.func,
  menuIsOpen: PropTypes.bool,
  instanceId: PropTypes.any,
};

export default ContactDropdown;
