import React, { useState, useEffect } from 'react';
import { createCookie, getCookie } from 'Services/cookie/core__cookies';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import PropTypes from 'prop-types';
import {
  DD,
  ContactDropdown,
  DL,
  DB,
} from 'UI/apps/ToggleLanguageApp/ToggleLanguage';
import { putRequest } from 'Services/http/core__axios';
import Constants from '../../../../project';

const ToggleLangaugeReact = ({ appConfig }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const getSelectedLanguage = () => {
    const languageId = getCookie('languageId');
    const languages = appConfig.language?.split(',');

    if (languageId && languages) {
      const foundLanguage = languages.find(
        lang => lang.split(':')[1] === languageId
      );
      if (foundLanguage) {
        return foundLanguage;
      }
    }

    return languages?.[0];
  };

  useEffect(() => {
    setSelectedLanguage(getSelectedLanguage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLanguageChange = value => {
    createCookie('languageId', value);

    const UserAuthData = FSBCustomerSession.getSession();
    let urlEndpoint = '/fsb-api-rest/uk/sportsbook/customer.fsb';

    if (UserAuthData && UserAuthData.accessToken) {
      urlEndpoint += `?access_token=${UserAuthData.accessToken}&details=true`;

      putRequest(urlEndpoint, { preferredLanguageId: value })
        .then(() => window.location.reload())
        .catch(() => window.location.reload());
    } else {
      window.location.reload();
    }
  };

  if (!selectedLanguage) {
    return null;
  }

  const selectedLanguageParts = selectedLanguage.split(':');
  const selectedValue = selectedLanguageParts[1];
  const selectedIcon = selectedLanguageParts[2]?.toUpperCase();

  return (
    <DD>
      <DB
        src={`${Constants.staticPath.basePath}react/olybet/flags/${selectedIcon}.svg`}
        width="17px"
        height="12px"
      />
      <ContactDropdown
        value={selectedValue}
        defaultValue={selectedValue}
        menuPlacement={'bottom'}
        options={appConfig.language?.split(',').map(lang => ({
          label: (
            <div>
              {lang.split(':')[0]}
              <DL
                src={`${
                  Constants.staticPath.basePath
                }react/olybet/flags/${lang.split(':')[2]?.toUpperCase()}.svg`}
              />
            </div>
          ),
          value: lang.split(':')[1],
        }))}
        space
        onChange={e => handleLanguageChange(e.value)}
      />
    </DD>
  );
};
export default ToggleLangaugeReact;

ToggleLangaugeReact.propTypes = {
  appConfig: PropTypes.object.isRequired,
};
